<template>
  <div ref="containerRef" class="protocolTable">
    <SearchAndFilter
      class="searchBar"
      :title="searchBarTitle"
      icon="logbook/record"
      date-range-picker
      :start-with-all-dates="startWithAllDates"
      with-user-filter
      :voyages="recentFiveVoyages"
      :component-name-for-filter="componentNameForFilter"
      @search-text="setSearchText"
      @filter-dates="filterDates"
      @filter-by-author="filterAuthor"
      @filter-by-voyage="filterVoyage"
    />

    <table ref="tableRef" v-scroll="onScroll" data-test="table-protocol" class="logbookTable white gray_first--text">
      <colgroup>
        <col v-if="approvalMode" />
        <col />
        <col v-for="index of Array(headersAdapted.length)" :key="index" />
      </colgroup>

      <tr
        v-if="!approvalMode && !disableAddButton && $ability.can('create', protocolResource)"
        class="extraRow addEntryRow"
      >
        <td colspan="100%">
          <slot name="componentBeforeTableStart">
            <button
              id="addEntryBtn"
              data-test="table-add-button"
              height="54px"
              class="addEntryButton white"
              width="100%"
              depressed
              tile
              @click="emitAddEntryRequest"
            >
              <Icon name="circle/plus" />
            </button>
          </slot>
        </td>
      </tr>

      <tr v-if="isLoading" class="defaultCursor text-center loading">
        <td colspan="100%" class="text-center py-4">
          <div class="loadingText gray_first--text">{{ loadingMessage }}</div>
          <v-progress-linear v-show="true" class="loading-bar mx-auto mt-4" color="primary" indeterminate />
        </td>
      </tr>

      <tr class="defaultCursor gray_second--text">
        <th v-if="approvalMode" ref="approvalHeaderRef" />
        <th ref="expansionHeaderRef" />
        <th v-for="(header, index) in headersAdapted" ref="tableHeadersRef" :key="index">
          <span>{{ header }}</span>
        </th>
      </tr>

      <tr v-if="!isLoading && protocolsGroupedForTable.length === 0" class="defaultCursor">
        <td colspan="100%" class="text-center">{{ $t('no_entries') }}</td>
      </tr>

      <tr v-if="startHeight > 0">
        <td colspan="100%" :style="'padding-top:' + startHeight + 'px'" />
      </tr>

      <template v-for="(protocol, index) in protocolsToShow">
        <tr v-if="protocol.id === groupHeaderId" :key="`group-${index}`" class="defaultCursor">
          <td v-if="approvalMode" class="groupHeader gray_fourth">
            <Checkbox
              :value="approvedGroupsHaveDate(protocol.protocol.timestamp)"
              :disabled="disableMoreApprovals && !approvedGroupsHaveDate(protocol.protocol.timestamp)"
              class="approvalCheckbox"
              @input="toggleApproveForAllProtocolsOnSameDate(protocol.protocol)"
            />
          </td>
          <td class="groupHeader gray_fourth" />
          <td :colspan="headersAdapted.length" class="groupHeader gray_fourth gray_second--text">
            <span>{{ protocol.eventTime }}</span>
          </td>
        </tr>
        <tr v-else :key="index" @click="$emit('protocol-clicked', protocol.protocol)">
          <td v-if="approvalMode" class="groupChild">
            <Checkbox
              :value="isProtocolMarkedForApproval(protocol.id)"
              :disabled="disableMoreApprovals && !isProtocolMarkedForApproval(protocol.id)"
              class="approvalCheckbox"
              @input="toggleProtocolForApproval(protocol.protocol)"
            />
          </td>
          <td v-if="expansionButtonColumnNeeded">
            <button
              v-if="detailsNonEmpty(protocol) || changeOrAuthorColumnNotShown"
              class="expansionButton"
              @click.stop="openOrCloseExpansion(protocol.id)"
            >
              <Icon v-if="idInExpansionsOpen(protocol.id)" name="arrow/chevronUp" />
              <Icon v-else name="arrow/chevronDown" />
            </button>
          </td>
          <td v-else />
          <td :class="{ crossed: protocol.deleted }">{{ protocol.eventTime }}</td>
          <td :class="{ fillWidth: !showDetails, crossed: protocol.deleted }">
            {{ protocol.type }}
          </td>
          <td v-if="showDetails" :class="{ fillWidth: showDetails, crossed: protocol.deleted }">
            <div class="detailsWrapper d-flex">
              <Icon
                v-if="protocol.unknownPropertiesPresent"
                class="unknownPropIcon"
                name="symbol/warningMarker"
                color="warning"
              />
              <span v-if="isProtocolEncrypted(protocol.protocol)">{{
                protocol.protocol.isDecrypting ? $tc('decrypting_content') : $tc('encrypted_content')
              }}</span>
              <span
                v-else
                ref="detailsSpansRef"
                :class="{ [`detailsSpan_${protocol.id}`]: true, hide: idInExpansionsOpen(protocol.id) }"
              >
                <ProtocolContentTypeDetails :details="protocol.details" />
              </span>
            </div>
          </td>
          <td v-if="showAuthor" :class="{ crossed: protocol.deleted }">{{ protocol.author }}</td>
          <td v-if="showChange && hasAtLeastOneChangeValue">
            <div v-if="protocol.change == '0'" class="changeWrapperTest gray_second--text" />
            <div v-else class="changeWrapper gray_second--text">{{ protocol.change }}</div>
          </td>
          <td v-if="hasAccessToApprovals">
            <Icon
              v-if="protocol.approved.second"
              data-test="icon-approval-second-success"
              name="circle/check"
              color="success"
            />
            <Icon v-else name="circle/X" color="gray_fourth" />
          </td>
          <td v-if="hasAccessToApprovals">
            <Icon
              v-if="protocol.approved.first"
              data-test="icon-approval-first-success"
              name="circle/check"
              color="success"
            />
            <Icon v-else name="circle/X" color="gray_fourth" />
          </td>
        </tr>
        <ProtocolExpanded
          v-if="idInExpansionsOpen(protocol.id)"
          :key="`expanded-${index}`"
          :protocol="protocol"
          :show-expanded-author="!showAuthor"
          :show-expanded-change="!showChange"
          @click.native="$emit('protocol-clicked', protocol.protocol)"
        />
      </template>

      <tr v-if="firstVisibleRow + protocolsPerPage < protocolsGroupedForTable.length">
        <td colspan="100%" :style="'padding-top:' + endHeight + 'px'" />
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import {
  ALL_RECORDS_PSEUDO_ID,
  ApprovalStage,
  AUTH_RESOURCES,
  Book,
  isSystemUser,
  logger,
  Protocol,
  User,
  Voyage,
} from '@anschuetz-elog/common';
import { isEqual, orderBy, takeRight, without } from 'lodash';
import moment from 'moment';
import Vue, {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  PropType,
  ref,
  toRef,
  watch,
} from 'vue';

import Checkbox from '#/components/Checkbox.vue';
import Icon from '#/components/Icon.vue';
import ProtocolContentTypeDetails from '#/components/protocol/ProtocolContentTypeDetails.vue';
import { getDetails } from '#/components/protocol/ProtocolUIConfigDetails';
import ProtocolExpanded from '#/components/ProtocolExpanded.vue';
import SearchAndFilter from '#/components/SearchAndFilter.vue';
import useFeathers from '#/compositions/useFeathers';
import useFind from '#/compositions/useFind';
import { ensureSingleRunningTask } from '#/compositions/useProcessingUtils';
import { MOMENT_WEEKDAY_DATE_YEAR } from '#/config/time';
import { getProtocolsForPdfExport, type ProtocolDataForPDFTable } from '#/export/pdf';
import { decryptProtocols, isProtocolEncrypted } from '#/helpers/ProtocolUtilities';
import i18n from '#/i18n';
import { enrichProtocolWithAllContents, getLatestObjectsWithHistory, ObjectWithHistory } from '#/lib/history';
import { DataForProtocolTable, ProtocolWithChanges } from '#/types';
import {
  dateStr,
  format,
  getChecklistTypeName,
  getProtocolTimestampAndEventTime,
  getProtocolTypeName,
  getUserInitials,
  getUserName,
  roundDateTime,
} from '#/utilities';

import { detailsAsString } from './ProtocolUIConfigDetails';

const ROW_HEIGHT = 32;

export default defineComponent({
  name: 'ProtocolTable',

  components: { Icon, SearchAndFilter, ProtocolExpanded, Checkbox, ProtocolContentTypeDetails },

  props: {
    protocols: {
      type: Array as PropType<Protocol[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
    searchBarTitle: {
      type: String,
      required: true,
    },
    loadingMessage: {
      type: String,
      required: true,
    },
    startWithAllDates: {
      type: Boolean,
    },
    triggerExportForPdf: {
      type: Boolean,
    },
    triggerExportForSpreadsheet: {
      type: Boolean,
    },
    bookId: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    approvalStage: {
      type: Number as PropType<ApprovalStage>,
      default: undefined,
    },
    hideDeleted: {
      type: Boolean,
    },
    componentNameForFilter: {
      type: String,
      default: undefined,
    },
    disableAddButton: {
      type: Boolean,
    },
  },
  emits: {
    'add-entry': (): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'date-filter': (dates: Date[]): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'displayed-protocol-count': (count: number): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'export-to-pdf': (value: ProtocolDataForPDFTable[]): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'export-to-spreadsheet': (value: ProtocolWithChanges[]): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'protocol-clicked': (protocol: Protocol): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:protocols-for-approval': (protocols: string[]): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'search-text': (searchParams: string): boolean => true,
  },

  setup(props, { emit }) {
    const feathers = useFeathers();

    // TODO: move to corresponding code parts to have related code at one place
    const { data: voyages } = useFind('voyage');
    const oneTextLineHeight = 25;
    const groupHeaderId = ref('groupHeader');
    const showDetails = ref(true);
    const showAuthor = ref(true);
    const showChange = ref(true);
    const expansionsOpen = ref<string[]>([]);
    const authorIdForFilter = ref<string | null>(null);
    const voyageIdForFilter = ref<string | null>(null);
    const windowHeight = ref(window.innerHeight);
    const protocolIdsForApproval = ref<string[]>([]);
    const approvedGroups = ref<Set<string>>(new Set<string>());
    const firstVisibleRow = ref(0);
    const dateFilter = ref<{ start: number; end: number }>();

    const protocols = toRef(props, 'protocols');
    const isLoading = toRef(props, 'isLoading');

    const triggerExportForPdf = toRef(props, 'triggerExportForPdf');
    watch(triggerExportForPdf, () => {
      if (decryptionRunning.value) {
        Vue.toasted.info(i18n.tc('wait_for_decryption'), {
          position: 'bottom-center',
          duration: 3000,
          className: 'notification-wait-for-decryption',
        });
        return;
      }
      emit('export-to-pdf', getProtocolsForPdfExport(book.value, protocols.value, getProtocolsForExport()));
    });

    const triggerExportForSpreadsheet = toRef(props, 'triggerExportForSpreadsheet');
    watch(triggerExportForSpreadsheet, () => {
      if (decryptionRunning.value) {
        Vue.toasted.info(i18n.tc('wait_for_decryption'), {
          position: 'bottom-center',
          duration: 3000,
          className: 'notification-wait-for-decryption',
        });
        return;
      }
      emit('export-to-spreadsheet', getProtocolsForExport());
    });

    const voyagesWithHistory = computed<ObjectWithHistory<Voyage>[]>(() => getLatestObjectsWithHistory(voyages.value));

    const latestVoyages = computed<Voyage[]>(() => voyagesWithHistory.value.map((voyageHistory) => voyageHistory.data));

    const bookId = toRef(props, 'bookId');
    const book = computed<Book | undefined>(() => {
      if (!bookId.value) {
        return undefined;
      }
      try {
        return feathers.get('localCache').getBookById(bookId.value);
      } catch (error) {
        logger.error(error);
      }
      return undefined;
    });

    const recentFiveVoyages = computed<Voyage[]>(() => {
      const voyagesSorted = latestVoyages.value;

      voyagesSorted.sort((a, b) => {
        return moment(b.startTime).unix() - moment(a.startTime).unix();
      });

      voyagesSorted.sort((a, b) => {
        if (!a.endTime && !b.endTime) {
          return 0;
        } else if (!a.endTime) {
          return -1;
        } else if (!b.endTime) {
          return 1;
        } else {
          return moment(b.endTime).unix() - moment(a.endTime).unix();
        }
      });

      return takeRight(voyagesSorted, 5);
    });

    const approvalStage = toRef(props, 'approvalStage');
    const approvalMode = computed(() => approvalStage.value !== undefined);

    const headers = computed<string[]>(() => {
      const headers = [
        import.meta.env.VITE_SMT_HANDLING_ENABLED ? i18n.tc('ships_mean_time') : i18n.tc('utc_time'),
        i18n.tc('type'),
        i18n.tc('details'),
        i18n.tc('author'),
        i18n.tc('change'),
      ];

      if (hasAccessToApprovals.value) {
        headers.push(i18n.tc('protocol.approval.stage_2_label'), i18n.tc('protocol.approval.stage_1_label'));
      }

      return headers;
    });

    const vm = getCurrentInstance();
    const $ability = vm?.proxy.$ability;
    const hasAccessToApprovals = computed(() => $ability?.can('read', AUTH_RESOURCES.APPROVAL) || false);

    function openOrCloseExpansion(id: string): void {
      if (expansionsOpen.value.includes(id)) {
        expansionsOpen.value = expansionsOpen.value.filter((e) => e !== id);
      } else {
        expansionsOpen.value.push(id);
      }
    }

    function idInExpansionsOpen(id: string): boolean {
      return expansionsOpen.value.includes(id);
    }

    onMounted(() => {
      window.addEventListener('resize', columnAdaptionByResize);
      columnAdaption();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', columnAdaptionByResize);
    });

    const truncatedDetails = ref<HTMLElement[]>([]);

    const expansionButtonColumnNeeded = computed(
      () => truncatedDetails.value.length > 0 || !showDetails.value || !showChange.value || !showAuthor.value,
    );

    watch([showChange, showAuthor, truncatedDetails], () => {
      if (truncatedDetails.value.length <= 0) {
        expansionsOpen.value = [];
      }
      if (showChange.value && showAuthor.value) {
        expansionsOpen.value = expansionsOpen.value.filter((id) => {
          const protocol = protocolsGroupedForTable.value.find((protocol) => protocol.id === id);
          return protocol && detailsNonEmpty(protocol);
        });
      }
    });

    const changeOrAuthorColumnNotShown = computed(() => !showChange.value || !showAuthor.value);

    const headersAdapted = computed<string[]>(() => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      let _headers = headers.value;

      if (!hasAtLeastOneChangeValue.value) {
        _headers = without(_headers, i18n.tc('change'));
      }

      if (!showChange.value) {
        _headers = without(_headers, i18n.tc('details'), i18n.tc('change'), i18n.tc('author'));
      }

      if (!showAuthor.value) {
        _headers = without(_headers, i18n.tc('details'), i18n.tc('author'));
      }

      if (!showDetails.value) {
        _headers = without(_headers, i18n.tc('details'));
      }

      return _headers;
    });

    function detailsNonEmpty(protocol: DataForProtocolTable): boolean {
      return protocol.details.length > 0;
    }

    const detailsSpansRef = ref<HTMLElement[]>([]);
    function columnAdaption(): void {
      if (window.innerWidth < 540) {
        showDetails.value = false;
      } else {
        showDetails.value = true;
        const widthForDetails = getWidthForDetails();
        const newTruncatedDetails: HTMLElement[] = [];
        detailsSpansRef.value.forEach((span) => ellipsisOff(span));
        detailsSpansRef.value.forEach((span) => {
          if (span.offsetHeight > oneTextLineHeight) {
            ellipsisOn(span, widthForDetails);
            newTruncatedDetails.push(span);
          }
        });
        if (!isEqual(truncatedDetails.value, newTruncatedDetails)) {
          truncatedDetails.value = newTruncatedDetails;
        }
      }
      if (window.innerWidth < 480) {
        showAuthor.value = false;
      } else {
        showAuthor.value = true;
      }

      if (window.innerWidth < 420) {
        showChange.value = false;
      } else {
        showChange.value = true;
      }
    }

    function columnAdaptionByResize(): void {
      windowHeight.value = window.innerHeight;
      columnAdaption();
    }

    const containerRef = ref<HTMLElement | null>(null);
    const approvalHeaderRef = ref<HTMLElement | null>(null);
    const expansionHeaderRef = ref<HTMLElement | null>(null);
    const tableHeadersRef = ref<HTMLElement[] | null>(null);
    function getWidthForDetails(): number {
      let width = 0;
      if (containerRef.value) {
        width = containerRef.value.offsetWidth;
      }
      if (approvalHeaderRef.value) {
        width -= approvalHeaderRef.value.offsetWidth;
      }
      if (expansionHeaderRef.value) {
        width -= expansionHeaderRef.value.offsetWidth;
      }
      if (tableHeadersRef.value) {
        tableHeadersRef.value.forEach((header, index) => {
          if (index !== 2) {
            width -= header.offsetWidth;
          } else {
            width -= parseFloat(`${window.getComputedStyle(header).getPropertyValue('padding-right')}`);
          }
        });
      }
      return Math.max(width, 0);
    }

    const hasAtLeastOneChangeValue = computed(() => {
      for (const protocol of protocolsGroupedForTable.value) {
        if (protocol.change != '0') {
          return true;
        }
      }
      return false;
    });

    function ellipsisOn(contentSpan: HTMLSpanElement, width: number): void {
      contentSpan.style.setProperty('width', `${width}px`, 'important');
      contentSpan.style.setProperty('text-overflow', 'ellipsis', 'important');
      contentSpan.style.setProperty('white-space', 'nowrap', 'important');
      contentSpan.style.setProperty('overflow', 'hidden', 'important');
      contentSpan.style.setProperty('display', 'block', 'important');
      contentSpan.setAttribute('truncated', 'on');
    }

    function ellipsisOff(contentSpan: HTMLSpanElement): void {
      contentSpan.style.setProperty('width', null);
      contentSpan.style.setProperty('text-overflow', null);
      contentSpan.style.setProperty('white-space', null);
      contentSpan.style.setProperty('overflow', null);
      contentSpan.style.setProperty('display', null);
      contentSpan.setAttribute('truncated', 'off');
    }

    function formatChangeValue(changeNumber: number): string {
      return changeNumber ? `+${changeNumber}` : changeNumber.toString();
    }

    const { startTask: singleRunningDecryptProtocols, taskRunning: decryptionRunning } =
      ensureSingleRunningTask(decryptProtocols);

    watch([isLoading, protocols], () => {
      if (!isLoading.value) {
        void singleRunningDecryptProtocols(protocols.value);
      }
    });

    const latestProtocolsWithNumberOfChanges = computed<ProtocolWithChanges[]>(() =>
      getLatestObjectsWithHistory(protocols.value).map(function mapProtocolHistoryCount({ data, history }) {
        const protocol = enrichProtocolWithAllContents(data, history[history.length - 1]);
        return { ...protocol, change: history.length };
      }),
    );

    const protocolsFilteredByBookAndApproval = computed<ProtocolWithChanges[]>(() => {
      // let protocolsFilteredByBook = generalFilteredProtocols.value;
      let protocolsFilteredByBook = latestProtocolsWithNumberOfChanges.value;
      if (bookId.value !== undefined && bookId.value !== ALL_RECORDS_PSEUDO_ID) {
        protocolsFilteredByBook = protocolsFilteredByBook.filter((protocol) => {
          return protocol.books.some((bookRef) => bookRef._id === bookId.value);
        });
      }

      let protocolsFilteredByApproval = protocolsFilteredByBook;
      const approvalProp: keyof Pick<Protocol, 'firstApproved' | 'secondApproved'> =
        approvalStage.value === 1 ? 'firstApproved' : 'secondApproved';
      if (approvalMode.value) {
        protocolsFilteredByApproval = protocolsFilteredByBook.filter((protocol) => {
          return !protocol[approvalProp];
        });
      }

      return protocolsFilteredByApproval;
    });

    const hideDeleted = toRef(props, 'hideDeleted');
    const filteredProtocols = computed<ProtocolWithChanges[]>(() => {
      if (!dateFilter.value) {
        return [];
      }
      const { start, end } = dateFilter.value;

      let filterProtocols = protocolsFilteredByBookAndApproval.value;

      if (hideDeleted.value) {
        filterProtocols = filterProtocols.filter((protocol) => !protocol.deleted);
      }

      filterProtocols = filterProtocols.filter((protocol) => {
        const timestampMillis = roundDateTime(protocol.timestamp).toDate().getTime();
        return timestampMillis >= start && timestampMillis <= end;
      });

      if (authorIdForFilter.value) {
        filterProtocols = filterProtocols.filter(
          (protocol) => protocol.author._id === authorIdForFilter.value && (!hideDeleted.value || !protocol.deleted),
        );
      }

      if (voyageIdForFilter.value) {
        const filteredVoyage = voyages.value.find((voyage) => voyageIdForFilter.value === voyage._id);

        filterProtocols = filterProtocols.filter(
          (protocol) =>
            isInRangeOfSelectedVoyages(protocol.createdTimestamp, filteredVoyage) &&
            (!hideDeleted.value || !protocol.deleted),
        );
      }

      return orderBy(filterProtocols, (protocol) => moment(protocol.timestamp), ['desc']);
    });

    function isInRangeOfSelectedVoyages(createdTimestamp: string, voyage: Voyage | undefined): boolean {
      if (voyage && voyage.startTime) {
        if (voyage.endTime) {
          return moment(createdTimestamp).isBetween(voyage.startTime, voyage.endTime);
        }
        return moment(createdTimestamp).isBetween(voyage.startTime, new Date());
      }
      return false;
    }

    const protocolsRestructuredForTable = computed<DataForProtocolTable[]>(() =>
      filteredProtocols.value.map((protocol) => {
        const { timestamp, eventTime } = getProtocolTimestampAndEventTime(protocol);
        return {
          protocol: protocol,
          id: protocol._id,
          eventTime,
          timestamp,
          details: getDetails(protocol),
          unknownPropertiesPresent: unknownPropertiesPresent(protocol),
          type: '_id' in protocol.type ? getProtocolTypeName(protocol) : getChecklistTypeName(protocol),
          approved: {
            first: protocol.firstApproved,
            second: protocol.secondApproved,
          },
          author: getUserInitials(protocol.author),
          change: formatChangeValue(protocol.change),
          deleted: protocol.deleted,
        };
      }),
    );

    const search = ref('');
    const protocolSearchIndex = computed<{ protocolJSON: string; id: string }[]>(() =>
      protocolsRestructuredForTable.value.map((protocol) => ({
        protocolJSON: [
          protocol.type.toLowerCase(),
          protocol.author.toLowerCase(),
          detailsAsString(protocol.details).toLowerCase(),
        ].join(' '),
        id: protocol.id,
      })),
    );

    const protocolsFilteredByTextSearch = computed<DataForProtocolTable[]>(() => {
      if (search.value.length < 1) {
        return protocolsRestructuredForTable.value;
      }

      const matchIds = protocolSearchIndex.value
        .filter((protocolSearchBasis) => protocolSearchBasis.protocolJSON.includes(search.value.toLowerCase()))
        .map((protocolSearchBasis) => protocolSearchBasis.id);
      return protocolsRestructuredForTable.value.filter((protocol) => matchIds.includes(protocol.id));
    });

    const protocolsGroupedForTable = computed<DataForProtocolTable[]>(() => {
      const groupedProtocols: DataForProtocolTable[] = [];
      if (protocolsFilteredByTextSearch.value.length < 1) {
        return [];
      }

      let currentDateForGrouping = moment(protocolsFilteredByTextSearch.value[0].timestamp).startOf('day');
      protocolsFilteredByTextSearch.value.forEach((protocol, index) => {
        const day = roundDateTime(protocol.timestamp).startOf('day');
        if (day.format('X') !== currentDateForGrouping.format('X') || index === 0) {
          const groupHeader = format(protocol.timestamp, MOMENT_WEEKDAY_DATE_YEAR);
          groupedProtocols.push({ ...protocol, id: 'groupHeader', eventTime: groupHeader });
          currentDateForGrouping = day;
        }
        groupedProtocols.push(protocol);
      });
      return groupedProtocols;
    });

    const protocolsPerPage = computed(() => Math.ceil(windowHeight.value / ROW_HEIGHT));

    const protocolsToShow = computed<DataForProtocolTable[]>(() => {
      if (protocolsGroupedForTable.value.length === 0) {
        return [];
      }
      const beginsWithGroupHeader = protocolsGroupedForTable.value[firstVisibleRow.value].id === groupHeaderId.value;
      const protocolsOnPage = protocolsGroupedForTable.value.slice(
        firstVisibleRow.value,
        firstVisibleRow.value + protocolsPerPage.value,
      );
      if (!beginsWithGroupHeader) {
        for (let i = firstVisibleRow.value; i >= 0; i--) {
          if (protocolsGroupedForTable.value[i].id === groupHeaderId.value) {
            return [protocolsGroupedForTable.value[i], ...protocolsOnPage];
          }
        }
      }
      return protocolsOnPage;
    });

    watch(protocolsToShow, () => {
      emit('displayed-protocol-count', protocolsToShow.value.length);
      columnAdaption();
    });

    onUpdated(() => {
      columnAdaption();
    });

    const startHeight = computed<number>(() => Math.max(0, (firstVisibleRow.value - 1) * ROW_HEIGHT));

    const endHeight = computed<number>(
      () => ROW_HEIGHT * (protocolsGroupedForTable.value.length - firstVisibleRow.value),
    );

    const tableRef = ref<HTMLTableElement | null>(null);
    const timeout = ref<number | null>(null);
    function onScroll(event: Event): void {
      // debounce if scrolling fast
      if (timeout.value !== null) {
        clearTimeout(timeout.value);
      }

      timeout.value = window.setTimeout(() => {
        if (!event.target) {
          return;
        }
        if (!tableRef.value) {
          throw new Error('table is not defined');
        }
        const offsetY = tableRef.value.offsetTop;
        const scrollTop = Math.max(0, window.scrollY - offsetY);
        const rows = Math.ceil(scrollTop / ROW_HEIGHT);

        firstVisibleRow.value =
          rows + protocolsPerPage.value > protocolsGroupedForTable.value.length
            ? Math.max(0, protocolsGroupedForTable.value.length - protocolsPerPage.value)
            : rows;
      }, 20);
    }

    function unknownPropertiesPresent(protocol: Protocol): boolean {
      try {
        const author = feathers.get('localCache').getUserById(protocol.author._id);
        if (author && isSystemUser(author)) {
          return !protocol.position;
        }
      } catch (error) {
        // author unknown
      }
      return false;
    }

    function getProtocolsForExport(): ProtocolWithChanges[] {
      const filteredProtocolIds = protocolsFilteredByTextSearch.value.map((protocol) => protocol.id);
      return filteredProtocols.value.filter((protocol) => {
        return filteredProtocolIds.includes(protocol._id);
      });
    }

    function filterDates(dates: Date[]): void {
      if (
        dateFilter.value &&
        dates[0].getTime() === dateFilter.value.start.valueOf() &&
        dates[1].getTime() === dateFilter.value.end.valueOf()
      ) {
        return;
      }
      firstVisibleRow.value = 0;
      emit('date-filter', dates);
      dateFilter.value = {
        ...dateFilter.value,
        start: dates[0].getTime(),
        end: dates[1].getTime(),
      };
    }

    function filterAuthor(authorId: string | null): void {
      firstVisibleRow.value = 0;
      authorIdForFilter.value = authorId;
      emitSearchText();
    }

    function filterVoyage(voyageId: string | null): void {
      firstVisibleRow.value = 0;
      voyageIdForFilter.value = voyageId;
      emitSearchText();
    }

    function setSearchText(value: string): void {
      firstVisibleRow.value = 0;
      search.value = value;
      emitSearchText();
    }

    function emitSearchText(): void {
      const searchParams: string[] = [];
      if (search.value.length > 0) {
        searchParams.push(`Search: "${search.value}"`);
      }
      if (authorIdForFilter.value !== null) {
        searchParams.push(`Author: ${getUserName(User.createRef(authorIdForFilter.value))}`);
      }
      if (voyageIdForFilter.value !== null) {
        const filteredVoyage = voyages.value.find((voyage) => voyageIdForFilter.value === voyage._id);
        if (filteredVoyage) {
          searchParams.push(`Voyage: ${filteredVoyage.voyageNumber}`);
        }
      }
      emit('search-text', searchParams.join(', '));
    }

    function emitAddEntryRequest(): void {
      emit('add-entry');
    }

    function isProtocolMarkedForApproval(protocolId: string): boolean {
      return protocolIdsForApproval.value.includes(protocolId);
    }

    function toggleProtocolForApproval(protocolForApproval: Protocol): void {
      const isAlreadyAdded = isProtocolMarkedForApproval(protocolForApproval._id);
      protocolIdsForApproval.value = isAlreadyAdded
        ? protocolIdsForApproval.value.filter((protocol) => protocol !== protocolForApproval._id)
        : [...protocolIdsForApproval.value, protocolForApproval._id];

      if (areAllProtocolsOfSameDateClicked(protocolForApproval)) {
        approvedGroups.value.add(getGroupKey(protocolForApproval));
      } else {
        approvedGroups.value.delete(getGroupKey(protocolForApproval));
      }

      emit('update:protocols-for-approval', protocolIdsForApproval.value);
    }

    function areAllProtocolsOfSameDateClicked(protocol: Protocol): boolean {
      const protocolsByDate = getProtocolIdsByDate(protocol.timestamp);
      return protocolsByDate.every((p) => isProtocolMarkedForApproval(p));
    }

    function approvedGroupsHaveDate(date: string): boolean {
      return approvedGroups.value.has(dateStr(date));
    }

    function getGroupKey(protocol: Protocol): string {
      return dateStr(protocol.timestamp);
    }

    function toggleApproveForAllProtocolsOnSameDate(protocol: Protocol): void {
      const protocolIds = getProtocolIdsByDate(protocol.timestamp);

      if (approvedGroups.value.has(getGroupKey(protocol))) {
        // remove all protocols from this date from protocolsForApproval array
        removeProtocolsFromProtocolsForApproval(protocolIds);
        approvedGroups.value.delete(getGroupKey(protocol));
      } else {
        // add all protocols from this date to protocolsForApproval array
        removeProtocolsFromProtocolsForApproval(protocolIds);
        addProtocolsToProtocolsForApproval(protocolIds);
        approvedGroups.value.add(getGroupKey(protocol));
      }
    }

    function removeProtocolsFromProtocolsForApproval(protocolsToRemove: string[]): void {
      protocolIdsForApproval.value = protocolIdsForApproval.value.filter(
        (id) => !protocolsToRemove.some((idToRemove) => id === idToRemove),
      );
      emit('update:protocols-for-approval', protocolIdsForApproval.value);
    }

    function addProtocolsToProtocolsForApproval(protocolsToAdd: string[]): void {
      protocolIdsForApproval.value = [...protocolIdsForApproval.value, ...protocolsToAdd];
      emit('update:protocols-for-approval', protocolIdsForApproval.value);
    }

    function getProtocolIdsByDate(timestamp: string): string[] {
      return filteredProtocols.value
        .filter((protocol) => {
          return dateStr(protocol.timestamp) === dateStr(timestamp);
        })
        .map((protocol) => protocol._id);
    }

    const allProtocolsForApprovalMarked = computed(() =>
      filteredProtocols.value.every((protocol) => isProtocolMarkedForApproval(protocol._id)),
    );

    const APPROVAL_LIMIT = 100;
    const disableMoreApprovals = computed(() => protocolIdsForApproval.value.length + 1 > APPROVAL_LIMIT);

    const protocolResource = AUTH_RESOURCES.PROTOCOL;

    return {
      recentFiveVoyages,
      setSearchText,
      filterDates,
      filterAuthor,
      filterVoyage,
      onScroll,
      approvalMode,
      headersAdapted,
      protocolResource,
      emitAddEntryRequest,
      allProtocolsForApprovalMarked,
      protocolsGroupedForTable,
      startHeight,
      protocolsToShow,
      groupHeaderId,
      approvedGroupsHaveDate,
      toggleApproveForAllProtocolsOnSameDate,
      isProtocolMarkedForApproval,
      toggleProtocolForApproval,
      expansionButtonColumnNeeded,
      detailsNonEmpty,
      changeOrAuthorColumnNotShown,
      openOrCloseExpansion,
      idInExpansionsOpen,
      showDetails,
      showAuthor,
      showChange,
      hasAtLeastOneChangeValue,
      hasAccessToApprovals,
      firstVisibleRow,
      protocolsPerPage,
      endHeight,
      tableRef,
      containerRef,
      tableHeadersRef,
      expansionHeaderRef,
      approvalHeaderRef,
      detailsSpansRef,
      protocolsRestructuredForTable,
      isProtocolEncrypted,
      disableMoreApprovals,
      approvedGroups,
    };
  },
});
</script>

<style scoped>
.protocolTable {
  /* display: flex;
  flex-flow: column; */
  margin: 8px;
}

.logbookTable {
  width: 100%;
  border-collapse: collapse;
}

.logbookTable > tr {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.logbookTable > tr > td {
  text-align: left;
  font-size: 16px;
  height: 32px;
}

.logbookTable > tr > th,
.logbookTable > tr.loading > td {
  z-index: 1;
  position: sticky;
  top: 54px;
}

.logbookTable > tr > th {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0.2px;
  height: 32px;
  background: var(--v-white-base);
}

.logbookTable > tr.loading > td {
  background: var(--v-white-base);
}

.logbookTable > tr:hover {
  cursor: pointer;
}

.logbookTable .defaultCursor:hover {
  cursor: default;
}

.logbookTable > tr > td:nth-child(2),
.logbookTable > tr > th:nth-child(2) {
  padding-left: 8px;
}

.logbookTable > tr > td:last-child,
.logbookTable > tr > th:last-child {
  padding-right: 8px;
}

.logbookTable > tr.addEntryRow > td:last-child {
  padding-right: 0;
}

.logbookTable > tr.loading ~ tr > th {
  top: 129px;
}

.logbookTable > tr > th:not(:last-child):not(:first-child) {
  padding-right: 20px;
}

.logbookTable > tr > td:not(.fillWidth):not(:first-child) {
  white-space: nowrap;
  width: 1px;
}

.fillWidth {
  width: 100%;
}

.logbookTable > colgroup > col:first-child {
  width: 8px;
}

.logbookTable > tr > td:not(:first-child):not(:last-child) {
  padding-right: 20px;
}

.logbookTable > tr > td.groupHeader {
  position: sticky;
  top: 84px;
  z-index: 2;
  padding-left: 8px;
}

.logbookTable > tr.loading ~ tr > td.groupHeader {
  top: 160px;
}

.groupChild {
  padding-left: 8px;
}

.extraRow {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.expansionButton {
  vertical-align: middle;
  height: 24px;
  width: 24px;
  margin-left: 8px;
}

.changeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-weight: 600;
  font-size: 14px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
}

.changeWrapperTest {
  font-size: 14px;
}

.loading-bar {
  width: calc(100% / 4);
}

.loadingText {
  font-size: '16px';
}

.unknownPropIcon {
  width: 24px;
  margin-right: 8px;
}

.approvalCheckbox {
  margin-top: 0;
}

.crossed {
  text-decoration: line-through;
}

.hide {
  visibility: hidden;
}

.addEntryButton {
  height: 54px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--v-gray_third-base);
}
</style>
