import { i18n } from '@anschuetz-elog/frontend-core';
import { registerSW } from 'virtual:pwa-register';
import Vue from 'vue';

import executeOfflineMigrations from './lib/offline/migrations';
import logger from './logger';
import store from './store';

export default function initServiceWorker(): void {
  if (!navigator || !navigator.serviceWorker) {
    logger.warn('Your browser does not support service workers.');
    return;
  }

  logger.info('Initializing service worker...');
  store.commit.serviceWorker.setUpdating(true);

  async function finishServiceWorkerInit(): Promise<void> {
    try {
      await executeOfflineMigrations();
    } catch (error) {
      logger.error('Offline migration execution failed', error);
    }
    store.commit.serviceWorker.setUpdating(false);
  }

  async function tryUpdate(registration: ServiceWorkerRegistration) {
    logger.info('Service worker checking for update...');
    try {
      const updateRegistration = await (registration.update() as unknown as Promise<ServiceWorkerRegistration>);
      if (!updateRegistration.installing && !updateRegistration.waiting) {
        // in this case the update did not found anything so we know we are up to date
        logger.info('Service worker is up to date.');
      }
    } catch (error) {
      logger.warn('Service worker update failed. Client is probably offline.', error);
    }
    await finishServiceWorkerInit();
  }

  registerSW({
    immediate: true,
    onOfflineReady() {
      logger.info('Service worker ready for offline');
      void finishServiceWorkerInit();
    },
    onRegisteredSW(url, registration) {
      logger.info('Service worker has been registered.', registration);
      if (registration && registration.active) {
        // if we have an active service worker try to update him
        void tryUpdate(registration);
      } else if (registration?.installing) {
        void tryUpdate(registration);
      }
    },
    onRegisterError() {
      Vue.toasted.error(i18n.tc('service_worker.installation_error'), {
        duration: 5000,
        action: [
          {
            text: i18n.tc('retry'),
            onClick: () => {
              window.location.reload();
            },
          },
          {
            text: i18n.tc('service_worker.install_ca_certificate'),
            push: {
              name: 'user-manual',
              hash: '#16-installing-the-elog-root-ca-certificate',
            },
          },
        ],
      });
      store.commit.serviceWorker.setUpdating(false);
    },
  });
}
